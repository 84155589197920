// btn
.btn {
	padding: 0.375rem 1.5rem;
	border-radius: 3px;
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
	font-family: $font-family-secondary;

	// btn-primary
	&.btn-primary {
		color: $white;

		&:hover {
			background-color: $secondary;
			border-color: $secondary;
			color: $white;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	display: inline-flex;
	align-items: center;
	font-weight: 600;
	font-family: $font-family-secondary;
	text-decoration: none;

	&::before {
		content: "\f178";
		margin-right: 10px;
		font-size: 20px;
		font-weight: 900;
		font-family: $font-awesome;
	}

	&:hover {
		color: $green;
	}
}

// btn-back
.btn-back {
	display: inline-flex;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	font-family: $font-family-secondary;
	text-decoration: none;

	&::before {
		content: "\f177";
		margin-right: 10px;
		font-weight: 900;
		font-family: $font-awesome;
	}

	&:hover {
		color: $green;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;

	&::before {
		content: "\f175";
		margin-right: 5px;
		font-weight: 900;
		font-family: $font-awesome;
	}

}
