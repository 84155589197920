// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
	&.bg-dark {
		color: #fff;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: #fff;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	padding: 120px 0;
	background: $green-light;
	font-weight: 600;

	@include media-breakpoint-up(lg) {
		font-size: 20px;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 125px;
		margin-top: -1px;
		background: url("/images/edge-up-white.svg") no-repeat center;
		background-size: 3000px 125px;
	}

	.container-two-columns {
		.container-holder {
			align-items: center;

			> .column {
				&.wide {
					@include media-breakpoint-up(md) {
						@include make-col(8);
					}
				}

				&.narrow {
					@include media-breakpoint-up(md) {
						@include make-col(4);
					}
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
	}

	.info {
		.subtitle {
			h5 {
				@include font-size($h2-font-size);
				@extend .handwritten;
			}
		}

		// accommodation
		.properties {
			@extend .d-flex;
			flex-direction: column;
			@extend .flex-wrap;
			@extend .list-unstyled;
			font-size: 14px;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
				color: $green;
			}
		}

		.scroll-to-content {
			margin-bottom: 1rem;
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

&.home {
	.lead-section {
		padding: 30px 0;

		&::before {
			display: none;
		}

		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 300px;
			margin-top: -1px;
			background: url("/images/edge-up-white.svg") no-repeat center bottom
				$green-light;
			background-size: 3000px 125px;
		}
	}
}

&.bundle-detail,
&.bundle-overview:not(.accommodation-book-terms-and-conditions) {
	.lead-section {
		padding: 120px 0 30px 0;

		&::before {
			display: none;
		}

		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 300px;
			margin-top: -1px;
			background: url("/images/edge-up-white.svg") no-repeat center bottom
				$green-light;
			background-size: 3000px 125px;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	position: relative;
	margin-bottom: 6vh;
	/*background-color: $white;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 125px;
		margin-top: -1px;
		background: url('/images/edge-down-green-light.svg') center;
		background-size: 2999px 125px;
	}*/
}

// collection-section
// =========================================================================
.collection-section {
	.container {
		@include media-breakpoint-down(lg) {
			max-width: unset;
		}
	}

	// collection
	.collection {
		.owl-stage-outer {
			margin-left: -15px;
			margin-right: -15px;
			padding: 30px 15px;

			@include media-breakpoint-up(xl) {
				margin-left: -30px;
				margin-right: -30px;
				padding: 30px;
			}
		}

		.card {
			@extend .default-card;
		}

		// owl
		.owl-nav {
			display: none;
		}

		.owl-dots {
			margin-top: 0;
		}
	}

	&.custom-bg-mid {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			height: 125px;
			margin-top: -1px;
			background: url("/images/edge-down-green-light.svg") center;
			background-size: 2999px 125px;
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 50%;
			height: 125px;
			margin-bottom: -1px;
			background: url("/images/edge-up-green-light.svg") center;
			background-size: 3000px 125px;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;

	&::before {
		content: "";
		z-index: 100;
		position: absolute;
		top: -20px;
		width: 55px;
		height: 50px;
		background-size: cover;

		@include media-breakpoint-up(lg) {
			top: -35px;
			width: 94px;
			height: 86px;
		}
	}

	&::after {
		content: "";
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 125px;
		margin-bottom: -1px;
		background: url("/images/edge-up-green-light.svg");
		background-position: center;
		background-size: 3000px 125px;
	}

	.column {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 480px;
		max-height: 760px;

		.item {
			align-items: flex-start;
			height: 75vh !important; // overide
			min-height: 480px;
			max-height: 760px;
			padding: unset; // overide

			.owl-container {
				position: relative;
				max-width: none;

				.owl-caption {
					max-width: 450px;
					text-shadow: none;
					text-align: left;
					margin-top: 30px;
					padding: 40px 25px 25px 25px;
					background: $green;
					border-radius: 15px 0 15px 0;
					box-shadow: 0 0 30px rgba($black, 0.25);

					.owl-title {
						@include font-size($h1-font-size);
						font-weight: 500;
					}

					.owl-btn {
						@extend .card-btn;
						background: none;
						border: none;
						padding: 0;
						color: $orange;
						font-size: 16px;

						&:hover {
							background: none;
							border: none;
							color: $green-light !important;
						}
					}
				}
			}
		}

		.owl-dots {
			display: none;
		}
	}

	&.caption-left {
		margin-top: calc(6vh + 50px);

		&::before {
			left: 35px;
			background-image: url("/images/arrow-left.svg");

			@include media-breakpoint-up(lg) {
				left: 75px;
			}
		}

		.owl-carousel {
			.item {
				.owl-container {
					.owl-caption {
						margin-left: 15px;
					}
				}
			}
		}
	}

	&.caption-right {
		margin-bottom: 30px;
		background: $green-light;

		&::before {
			right: 35px;
			background-image: url("/images/arrow-right.svg");

			@include media-breakpoint-up(lg) {
				right: 75px;
			}
		}

		&::after {
			background-image: url("/images/edge-up-white.svg");
		}

		.owl-carousel {
			.item {
				.owl-container {
					display: flex;
					justify-content: flex-end;

					.owl-caption {
						margin-right: 15px;
						padding-top: 25px;
					}
				}
			}
		}
	}
}

// content-small-section
// =========================================================================
.content-small-section {
	padding-top: 0 !important;

	.container {
		max-width: 960px;

		.container-holder {
			align-items: center;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	position: relative;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;

				@include media-breakpoint-up(lg) {
					margin-bottom: -80px;

					.item {
						@include make-col(6);
						margin-bottom: 80px;
					}
				}
			}
		}

		.card {
			@extend .default-card;
		}
	}

	// mini-filter
	.mini-filter {
		position: relative;
		margin-bottom: 6vh;
		padding: 15px 30px 0 30px;
		border-radius: 15px 0 15px 0;
		background: $white;
		box-shadow: 0 0 30px rgba($black, 0.5);

		&::before {
			content: "";
			z-index: 100;
			position: absolute;
			top: -20px;
			right: 15px;
			width: 55px;
			height: 50px;
			background: url("/images/arrow-right.svg");
			background-size: cover;

			@include media-breakpoint-up(lg) {
				top: -35px;
				right: 30px;
				width: 94px;
				height: 86px;
			}
		}

		.form-group {
			label {
				font-size: 14px;
				font-weight: 600;
			}

			.form-control {
				height: 40px;
				border: 1px solid #999;
				border-radius: 2px;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin-bottom: 6vh;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-bottom: 6vh;
}

// booking-section
// =========================================================================
.booking-section {
	position: relative;
	margin-top: calc(6vh + 125px);
	margin-bottom: 125px;
	background: $green-light;

	&::before {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 0;
		right: 0;
		height: 125px;
		margin-bottom: -1px;
		background: url("/images/edge-up-green-light.svg") center;
		background-size: 3000px auto;
	}

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		height: 125px;
		margin-top: -1px;
		background: url("/images/edge-down-green-light.svg") center;
		background-size: 2999px 125px;
	}

	h2 {
		margin-bottom: 30px;
	}
}
