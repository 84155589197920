// headings
h1,
h2 {
	font-weight: 300;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	background: $white;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border: none;
	border-radius: 0 0 30px 0;
	box-shadow: 0 0 30px rgba($black, 0.25);

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 30px;
		}

		.card-caption {
			display: flex;
			flex-direction: column;

			.card-subtitle {
				order: -1;
				@extend .handwritten;
				font-size: 24px;
			}

			.card-title-link {
				color: $green;

				.card-title {
					font-weight: 600;
				}
			}
		}
	}
}

// container
.container-small {
	max-width: 720px;
}

// handwritten
.handwritten {
	color: $black;
	font-weight: 400;
	font-family: $font-family-tertiary;
}

// link-arrow
.link-arrow {
	display: inline-flex;
	align-items: center;
	font-weight: 600;
	font-family: $font-family-secondary;
	text-decoration: none;

	&::before {
		content: "\f178";
		margin-right: 10px;
		font-weight: 900;
		font-family: $font-awesome;
	}

	&:hover {
		color: $green;
	}
}

// icons-collection
.icons-collection {
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-down(xs) {
					@include make-col(6);
				}
			}
		}
	}
	.card {
		min-height: 150px;
		border: 2px solid transparent;
		border-radius: 0 0 30px 0;
		box-shadow: 0 0 30px rgba($black, 0.25);

		&[href]:hover {
			border: 2px solid $green;
		}

		.card-image {
			margin: 15px;
			color: $green;
			font-size: 60px;

			img {
				max-height: 60px;
			}
		}

		.card-body {
			padding: 0;

			.card-caption {
				flex: none;
				margin-top: auto;
				text-align: center;

				.card-title {
					color: $green;
					font-size: 16px;
					font-weight: 400;
				}

				.card-subtitle,
				.card-description {
					display: none;
				}
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			box-shadow: 0 0 30px rgba($black, 0.25);
		}
	}

	.gallery-thumbs {
		.gallery-item {
			&.last-item {
				.gallery-link {
					border-radius: 0 0 30px 0;
				}
			}
		}
	}
}
