// text-dark
.text-dark {
	color: $text-dark !important;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $text-dark;
	}

	// a
	a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {
		color: $text-dark;
		text-decoration: underline;

		&:hover {
			color: lighten($text-dark, 25%);
		}
	}

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			&::before {
				color: $text-dark;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		&.slider {
			.owl-nav {

				.owl-prev,
				.owl-next {
					color: $text-dark;

					&:hover {
						color: lighten($text-dark, 25%);
					}
				}
			}

			.owl-dots {
				.owl-dot {
					span {
						border-color: $text-dark;
					}

					&:hover,
					&.active {
						span {
							background: $text-dark;
						}
					}
				}
			}
		}
	}
}

// text-light
.text-light {
	color: $text-light !important;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $text-light;
	}

	// a
	a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {
		color: $green-light;
		text-decoration: underline;

		&:hover {
			color: $orange;
		}
	}

	// custom-list
	.custom-list,
	.custom-list-extra {
		li {
			&::before {
				color: $text-light;
			}
		}
	}

	// alert
	.alert-danger {
		background: transparent;
	}

	// owl-carousel
	.owl-carousel {
		&.slider {
			.owl-nav {

				.owl-prev,
				.owl-next {
					color: $text-light;

					&:hover {
						color: darken($text-light, 25%);
					}
				}
			}

			.owl-dots {
				.owl-dot {
					span {
						border-color: $text-light;
					}

					&:hover,
					&.active {
						span {
							background: $text-light;
						}
					}
				}
			}
		}
	}
}
