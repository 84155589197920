// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	&::after {
		content: "";
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 125px;
		margin-bottom: -1px;
		background: url('/images/edge-up-green-light.svg') center;
		background-size: 3000px 125px;
	}

	.owl-carousel {
		.item {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 50%;
				max-height: 180px;
				background: linear-gradient(180deg, rgba($black, 0.4) 0%, rgba($black, 0) 100%);
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 50%;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.2) 100%);
			}

			.owl-container {
				z-index: 100;

				.owl-caption {
					margin: 0 30px;

					.owl-title {
						margin-bottom: 0;
						@include font-size($h2-font-size);

						@include media-breakpoint-up(xl) {
							font-size: 48px;
						}

						font-family: $font-family-tertiary;
					}

					.owl-subtitle {
						margin-bottom: 0;
						@include font-size($h1-font-size);

						@include media-breakpoint-up(xl) {
							font-size: 80px;
						}
					}
				}
			}
		}
	}

	// owl-dots
	.owl-dots {
		display: none;
	}
}
