.notice-bar {
	display: none;

	z-index: 996;
	position: fixed;
	max-width: 330px;
	right: 15px;
	bottom: 15px;

	@include media-breakpoint-up(lg) {
		right: 30px;
		bottom: 30px;
	}

	padding: 30px;
	color: $black;
	font-size: $font-size-base;

	background: $white;
	border-radius: 0 0 30px 0;
	box-shadow: 0 0 30px rgba($black, 0.25);

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $green;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	a {
		color: $orange;

		&:hover {
			color: $green;
		}
	}

	&-content {
		padding-right: 45px;
	}

	&-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;

		&-icon {
			font-size: 22px;

			i {
				line-height: 1;
			}

		}

		&-title {
			display: none;
		}
	}
}

.notice-bar.show {
	display: block;
}
