// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	transition: 0.5s;
	background: $green-light;

	&.sticky {
		box-shadow: 0 0 30px rgba($black, 0.35);
	}

	.container-fluid {
		.container-holder {
			>.column {
				padding: 40px 45px;
				align-items: center;
				justify-content: flex-end;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding: 20px 15px;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 36px;
			height: 36px;
			padding: 0;
			border: none;
			background: $white;
			border-radius: 3px;
			color: $green;
			font-size: 20px;
			font-weight: 700;
			text-transform: uppercase;
			transition: color 0.5s;

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	&.sticky {
		@include media-breakpoint-down(lg) {
			.menu-toggle {
				.navbar-toggler {
					background: $orange;
					color: $white;
				}
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		width: 180px;
		padding: 20px 30px 55px 20px;

		@include media-breakpoint-up(xl) {
			width: 300px;
			padding: 30px 40px 90px 40px;
		}

		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $white;
			border-radius: 0 0 40px 0;
			transform: skew(0deg, -19deg);
			box-shadow: 0 0 30px rgba($black, 0.5);
			transform-origin: top left;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 4;

			.navbar-nav {
				padding: 45px 0 15px 0;
				flex-flow: row wrap;

				>.nav-item {
					flex: 1;
					min-width: 200px;
					margin: 0 15px 15px 0;

					@include media-breakpoint-down(xs) {
						max-width: calc(50% - 15px);
						min-width: auto;
					}

					>.nav-link {
						display: flex;
						align-items: center;
						color: $green;
						font-size: 16px;
						font-weight: 700;
						font-family: $font-family-secondary;

						&:hover {
							color: $orange;
						}

						&.dropdown-toggle {
							&::after {
								display: none;
							}
						}
					}

					&.active {
						>.nav-link {
							color: $orange;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						display: block !important;
						position: static !important;
						transform: none !important;
						margin: 0;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-link {
							padding-top: 0.25rem;
							padding-bottom: 0.25rem;
							color: $green;
							font-size: 14px;
							font-weight: 600;
							font-family: $font-family-primary;

							&:hover {
								color: $orange;
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}
						}

						&.active {
							.nav-link {
								color: $orange;
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				color: $green;
				font-family: $font-family-secondary;

				>.nav-item {
					margin: 0 15px;

					>.nav-link {
						padding-left: 0 !important;
						padding-right: 0 !important;
						border-bottom: 2px solid transparent;
						color: inherit;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							border-bottom-color: inherit;
						}
					}

					&.show,
					&.active {
						>.nav-link {
							border-bottom-color: inherit;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						left: 0 !important;
						transform: translateX(0) !important;
						margin-top: 10px;
						padding: 0;
						background: transparent;
						border: none;
						border-radius: 0;
						box-shadow: none;

						&::before,
						&::after {
							display: none;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 600;
							line-height: 1.2;
							font-family: $font-family-primary;
							white-space: nowrap;

							.nav-link {
								padding-left: 0;
								color: $green;

								&:hover {
									color: $orange;
								}
							}

							&.active {
								.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}
	}

	// book-button
	div.book-button {
		margin-left: 15px;

		@media only screen and (max-width: 439px) {
			display: none;
		}

		ul {
			li {
				a {
					@extend .btn;
					@extend .btn-primary;
					display: inline-flex;
					align-items: center;
					height: 36px;
					padding: 0.375rem 0.75rem !important;
					font-size: 16px !important;
					font-weight: 700 !important;

					@include media-breakpoint-up(xl) {
						height: 46px;
						padding: 0 1.5rem !important;
					}
				}
			}
		}
	}

	// quick-access
	div.quick-access {
		margin-left: 15px;

		ul {
			li {
				font-size: 22px;

				+li {
					margin-left: 10px;
				}

				a {
					color: $white;

					.list-item-title {
						display: none;
					}

					&:hover {
						color: $orange;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 15px;

		@include media-breakpoint-up(xl) {
			margin-left: 20px;
		}

		&.mobile {
			display: block;

			@include media-breakpoint-up(md) {
				display: none;
			}

			.dropdown-toggle {
				display: flex !important;
				align-items: center;
				border: none !important;
				border-radius: 0 !important;
				box-shadow: none !important;
				color: $white;
				font-size: 16px;
				line-height: 36px;
				font-family: $font-family-secondary;
				text-transform: uppercase;
			}

			.dropdown-menu {
				.dropdown-item {
					img {
						width: 22px;
					}
				}
			}
		}

		&.desktop {
			display: none;

			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}

	@include media-breakpoint-down(md) {
		&.sticky {
			.site-switcher {
				.dropdown-toggle {
					color: $black;
				}
			}
		}
	}

	// menu-overlay
	.menu-overlay {
		display: none;

		.column {
			justify-content: space-between !important;
			padding-top: 200px !important;
			padding-bottom: 15px !important;

			@include media-breakpoint-down(lg) {
				padding-top: 15px !important;
			}
		}

		// footer-link
		.footer-link {
			.list {
				flex-direction: column;

				.list-item {
					.link {
						display: inline-flex;
						align-items: center;
						color: $orange;
						font-size: 14px;
						font-weight: 600;
						text-decoration: none;

						&:hover {
							color: $green;
						}

						&::before {
							content: "\f178";
							margin-right: 10px;
							font-size: 16px;
							font-weight: 900;
							line-height: 1;
							font-family: $font-awesome;
						}
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			margin-right: -10px;

			.list {
				.list-item {
					.link {
						display: block;
						padding: 0 10px;
						color: $green;
						font-size: 24px;

						&:hover {
							color: $orange;
						}
					}
				}
			}
		}
	}
}

// home
&.home {
	.header {
		position: fixed;
		background: transparent;

		.menu {
			.navbar-nav {
				color: $white;
			}
		}

		&.sticky {
			background: $white;

			.menu {
				.navbar-nav {
					color: $green;
				}
			}

			div.quick-access {
				ul {
					li {
						a {
							color: $green;

							&:hover {
								color: $orange;
							}
						}
					}
				}
			}
		}
	}
}

// navbar-active
&.navbar-active {
	&::before {
		content: "";
		z-index: 997;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, 0.25);
	}

	.header {
		z-index: 998;
		background: $white;
		box-shadow: 0 0 30px rgba($black, 0.35);

		@include media-breakpoint-down(sm) {
			bottom: 0;
			overflow-y: scroll;
		}

		@include media-breakpoint-down(lg) {
			.logo {
				padding-bottom: 0;

				&::before {
					display: none;
				}
			}
		}

		.menu {
			.navbar-nav {
				color: $green;
			}
		}

		div.quick-access {
			ul {
				li {
					a {
						color: $green;

						&:hover {
							color: $orange;
						}
					}
				}
			}
		}

		.site-switcher {
			&.mobile {
				.dropdown-toggle {
					color: $green;
				}
			}
		}
	}

	.menu-overlay {
		display: block;
	}
}

.menu-overlay-bg {
	display: none;
	z-index: 997;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($black, 0.25);
}
