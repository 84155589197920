// mini-sab
.mini-sab {
	background: $green-light;

	.container {
		position: relative;

		.container-holder {
			position: relative;
			z-index: 100;

			@include media-breakpoint-down(lg) {
				margin-top: -175px;
			}

			@include media-breakpoint-up(lg) {
				position: absolute;
				left: 15px;
				right: 15px;
				bottom: 50px;
			}

			// heading
			.heading {
				z-index: 101;
				position: relative;
				@include font-size($h3-font-size);
				margin-bottom: 5px;
				color: #fff;
				font-weight: 600;
				font-family: $font-family-secondary;
				text-shadow: $shadow;

				@include media-breakpoint-up(xl) {
					font-size: 30px;
				}
			}
		}
	}
}

// w3-vue-booking-wrapper
.w3-vue-booking-wrapper {

	&.w3-matrix-widget,
	&.w3-searchbook-widget,
	&.w3-minisearchbook-widget {

		.w3-filter-collection {
			position: relative;
			padding: 15px 30px;
			border-radius: 15px 0 15px 0;
			background: $white;
			box-shadow: 0 0 30px rgba($black, 0.5);

			&::before {
				content: "";
				z-index: 100;
				position: absolute;
				top: -20px;
				right: 15px;
				width: 55px;
				height: 50px;
				background: url('/images/arrow-right.svg');
				background-size: cover;

				@include media-breakpoint-up(lg) {
					top: -35px;
					right: 30px;
					width: 94px;
					height: 86px;
				}
			}

			// filters
			.filters {

				.filter-element {
					&:not(.filter-context-button) {
						min-width: 200px;
					}

					// filter-context-travelgroup
					&.filter-context-travelgroup {
						.input-holder {
							.label {
								&::after {
									content: "\f500";
									margin-left: 2px;
									color: $green;
									font-size: 14px;
									font-weight: 900;
									font-family: $font-awesome;
								}
							}
						}
					}

					// filter-context-stay
					&.filter-context-stay {
						.input-holder {
							.label {
								&::after {
									content: "\f6bb";
									margin-left: 2px;
									color: $green;
									font-size: 14px;
									font-weight: 900;
									font-family: $font-awesome;
								}
							}
						}
					}

					// filter-context-date
					&.filter-context-date {
						.label {
							&::after {
								content: "\f073";
								margin-left: 2px;
								color: $green;
								font-size: 14px;
								font-weight: 900;
								font-family: $font-awesome;
							}
						}
					}

					// filter-context-object_characteristic
					&.filter-context-object_characteristic {
						.input-holder {
							.label {
								&::after {
									content: "\f46c";
									margin-left: 2px;
									color: $green;
									font-size: 14px;
									font-weight: 900;
									font-family: $font-awesome;
								}
							}
						}
					}
				}
			}
		}
	}

	&.w3-matrix-widget {
		.w3-filter-collection {
			margin-bottom: 0 !important;
			border-radius: 15px 0 0 0;
			background: #f8f9fa;
			box-shadow: none;
		}

		.matrix .available {
			min-height: 52px;
		}
	}

	&.w3-searchbook-widget {

		.accommodation-list .list-item-content .list-item-image-slider .slick-prev:before {
			content: "\f053";
			font-family: $font-awesome;
			font-weight: 900;
		}

		.accommodation-list .list-item-content .list-item-image-slider .slick-next:before {
			content: "\f054";
			font-family: $font-awesome;
			font-weight: 900;
		}
	}
}
