// footer
.footer {
	position: relative;
	margin-top: calc(8vh + 219px);
	padding: 30px 0;
	background: $green;
	color: $white;

	@include media-breakpoint-down(sm) {
		margin-top: 130px;
	}

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 219px;
		background: url('/images/footer-skyline.svg') center bottom $white;
		background-size: 3000px 219px;

		@include media-breakpoint-down(md) {
			height: 100px;
			background-size: cover;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	a {
		color: $green-light;

		&:hover {
			color: $white;
		}
	}

	// footer-outro
	.footer-outro {
		margin-bottom: 6vh;

		.column {
			flex-flow: row wrap;
		}

		// footer-text
		.footer-text {
			width: 100%;
		}

		// footer-link
		.footer-link {
			.list {
				.list-item {
					margin: 15px 15px 0 0;

					.link {
						@extend .btn;
						display: inline-flex;
						align-items: center;
						padding: 0.5rem 1rem !important;
						background-color: $green-light;
						border-color: $green-light;
						color: $black;
						font-size: 16px !important;
						font-weight: 400 !important;

						&:hover {
							background-color: $orange;
							border-color: $orange;
							color: $white;
						}

						&::before {
							content: "\f178";
							margin-right: 10px;
							font-size: 20px;
							font-weight: 900;
							line-height: 1;
							font-family: $font-awesome;
						}
					}
				}
			}

		}

		// footer-logolink
		.footer-logolink {
			margin-top: 15px;

			.list {

				.list-item {
					margin: 0 15px;

					.link {
						display: block;
						font-size: 36px;
						line-height: 42px;
					}
				}
			}
		}

	}

	// footer-sitelinks
	.footer-sitelinks {
		margin-top: 10vh;

		.container-holder {
			max-width: 800px;

			>.column {
				@include media-breakpoint-down(xs) {
					@include make-col(6);
				}
			}
		}

		font-size: 14px;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-bottom: 4px;
			}
		}
	}

	// footer-partners
	.footer-partners {
		@include media-breakpoint-down(lg) {
			margin-top: 6vh;
		}

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			@include media-breakpoint-up(xl) {
				position: absolute;
				right: 15px;
				bottom: 0;
				text-align: right;

				li {
					margin-top: 30px;
				}
			}

			@include media-breakpoint-down(lg) {
				display: flex;
				flex-flow: row wrap;
				align-items: center;

				li {
					margin: 15px 15px 0 0;
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		margin-top: 6vh;
		font-size: 12px;

		ul {
			display: flex;
			flex-flow: row wrap;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-right: 30px;

				a {
					color: $white !important;
					text-decoration: none !important;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
